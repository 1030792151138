.header {
  display: flex;
  gap: 24px;
  padding: 16px 32px;
  justify-content: flex-end;
  background-color: var(--menu-backgound-color);
  border-bottom: 1px solid var(--gray-border);
}

.delimiter {
  border-right: 1px solid var(--gray-border);
}

.notification {
  position: relative;
}

.bubble {
  position: absolute;
  background: var(--error);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 5px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
