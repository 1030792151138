.price {
  display: flex;
  justify-content: space-between;
}

.plan {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.planCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}


.planCard::after {
  content: ' ';
  background: conic-gradient(from 90deg at 1px 1px,#0000 90deg,var(--primary-light) 0);
  background-size: 2rem 2rem;
  box-shadow: inset 0 0 1rem 0 white;
  position: absolute;
  left: -50px;
  top: -50px;
  transform: rotateZ(45deg);
  z-index: -1;
  width: 200px;
  height: 200px;
}

.planCard svg {
  stroke: currentColor;
}