.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.icon svg:not(:global(.no-stroke)) {
  stroke: currentColor;
}

.animation {
  background: var(--text-white);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.primary {
  color: var(--primary);

}

.primary .animation {
  box-shadow:
    0 0 0 8px var(--text-white),
    0 0 0 10px var(--primary-light),
    0 0 0 18px var(--text-white),
    0 0 0 20px var(--primary-light),
    0 0 0 28px var(--text-white),
    0 0 0 30px var(--primary-light);
}

.danger {
  color: var(--error);
}

.danger .animation {
  box-shadow:
    0 0 0 8px var(--text-white),
    0 0 0 10px var(--error-light),
    0 0 0 18px var(--text-white),
    0 0 0 20px var(--error-light),
    0 0 0 28px var(--text-white),
    0 0 0 30px var(--error-light);
}