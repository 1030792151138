.typeahead {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.required {
  color: var(--error);
}

.typeahead ul {
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  left: 0;
  right: 0;

  background-color: white;
  box-shadow: 0 4px 14px -9px var(--gray-neutral);
  border: 1px solid var(--gray);
  border-radius: 8px;
  z-index: 10;
}

.typeahead ul li {
  padding: 10px;
}

.typeahead ul li:hover,
.typeahead ul li.active {
  background-color: var(--gray-100);
}