.colorRow,
.colorRowClassic {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
}

.colorRowClassic {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}


.colorButton {
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--gray-border);
  background-color: transparent;
  color: var(--text-black);
}

.colorButton.active {
  background-color: var(--primary);
  color: var(--text-white)
}