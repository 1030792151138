.toggle {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
}

.toggle .switch {
  display: inline-block;
  position: relative;
  width: 44px;
  height: 24px;
  /* height: 34px;
  width: 60px; */
}

.toggle .switch input {
  display: none;
}

.toggle .slider {
  background-color: var(--gray-300);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.toggle .slider:before {
  background-color: var(--text-white);
  content: "";
  bottom: 3px;
  left: 3px;
  position: absolute;
  transition: .4s;
  height: 18px;
  width: 18px;
}

.toggle input:checked+.slider {
  background-color: var(--primary);
}

.toggle input:checked+.slider:before {
  transform: translateX(18px);
}

.toggle .slider.round {
  border-radius: 24px;
}

.toggle .slider.round:before {
  border-radius: 50%;
}