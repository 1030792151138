.portalContainer {
  display: block;
}

.container {
  position: absolute;
}

.popupContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog {
  position: absolute;
  background-color: white;
  box-shadow: 0 4px 14px -9px var(--gray-neutral);
  border: 1px solid var(--gray);
  border-radius: 8px;
  z-index: 10;
}

/* .popupContainer  .dialog {
  min-width:500px;
} */

.overlay,
.invisibleOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.overlay {
  opacity: 0.7;
  background: var(--gray-overlay);
}

.dialogHeader {
  padding: 16px;
  border-bottom: 1px solid var(--gray);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialogBody {
  padding: 16px;
  overflow: auto;
  max-height: 90vh;
}

.noPaddings {
  padding: 0;
}