.tabs {
  display: flex;
  padding: 4px;
  gap: 4px;
  border-radius: 6px;
  background: var(--gray-100);
}

.tab {
  display: flex;
  color: var(--gray-400);
  background: transparent;
  border: none;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.tab.active {
  color: var(--text-black);
  border-radius: 4px;
  border: 1px solid var(--gray-300);
  background: var(--text-white);
  box-shadow: 0 1px 2px 0 var(--box-shadow-color);
}