.required {
  color: var(--error);
}

.dropdown {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.dropdown button {
  font-weight: 400;
  display: flex;
  justify-content: space-between;
}


.inputField.error label {
  color: var(--error);
}

.dropdown.error button {
  border-color: var(--error);
  color: var(--error);
}

.dropdown.error button:hover:not(:disabled) {
  border-color: var(--error);
}

.dropdown.error button:focus:not(:disabled),
.dropdown.error button:focus-visible:not(:disabled) {
  border-color: var(--error);
  outline-color: var(--error);
  box-shadow: 0 0 0 4px var(--error-light);
}


.dropdown.error span {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--error);
}