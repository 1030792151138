.button {
  margin: 32px 0 16px 0;
  width: 100%;
}

.p {
  display: inline-block;
}

.p a {
  color: var(--text-text-black);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration-line: underline;
}

.p a:hover {
  color: var(--primary);
  text-decoration-line: none;
}