.wrapper {
  display: flex;
  height: 100%;
  font-family: IBM Plex Sans;
  color: var(--text-black);
  background-color: var(--app-backgound-color);
}

.wrapper main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.animatedBox {
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  height: 160px;
  margin: 0 auto 0 auto;
  transform-origin: bottom;
  width: 160px;
  animation-name: bounce-7;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
}


@keyframes bounce-7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1.1, .9) translateY(0);
  }

  30% {
    transform: scale(.9, 1.1) translateY(-100px);
  }

  50% {
    transform: scale(1.05, .95) translateY(0);
  }

  57% {
    transform: scale(1, 1) translateY(-7px);
  }

  64% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}