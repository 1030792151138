.inputField {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 16px;

  box-sizing: border-box;
}

.inputField.noMargin {
  margin: 0;
}

.inputField.fullWidth {
  width: 100%;
}

.inputField label {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: var(--gray-800);
}

.inputField input,
.inputField textarea {
  border: 1px solid var(--gray-300);
  padding: 10px 14px 10px 14px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 var(--box-shadow-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--text-black);
  font-family: inherit;
  resize: none;
}

.inputField input:hover:not(:disabled),
.inputField textarea:hover:not(:disabled) {
  border-color: var(--primary);
}

.inputField input:focus:not(:disabled),
.inputField input:focus-visible:not(:disabled),
.inputField textarea:focus:not(:disabled),
.inputField textarea:focus-visible:not(:disabled) {
  border-color: var(--primary);
  outline-color: var(--primary);
  box-shadow: 0 0 0 4px var(--primary-light);
}

.inputField input:disabled,
.inputField textarea:disabled {
  border-radius: 8px;
  border: 1px solid var(--gray-300);
  background: var(--gray-100);
  box-shadow: 0 1px 2px 0 var(--box-shadow-color);
}

.inputField span:not(.required) {
  display: none;
}

.inputField.error label {
  color: var(--error);
}

.inputField.error input,
.inputField.error textarea {
  border-color: var(--error);
  color: var(--error);
}

.inputField.error input:hover:not(:disabled),
.inputField.error textarea:hover:not(:disabled) {
  border-color: var(--error);
}

.inputField.error input:focus:not(:disabled),
.inputField.error input:focus-visible:not(:disabled),
.inputField.error textarea:focus:not(:disabled),
.inputField.error textarea:focus-visible:not(:disabled) {
  border-color: var(--error);
  outline-color: var(--error);
  box-shadow: 0 0 0 4px var(--error-light);
}


.inputField.error span {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--error);
}

.required {
  color: var(--error);
}

.inputContainer {
  position: relative;
}

.inputContainer input {
  width: 100%;
}

.inputContainer.start>svg {
  position: absolute;
  top: 13px;
  left: 10px;
  color: var(--gray-400);
}

.inputContainer.end>button {
  position: absolute;
  top: 5px;
  right: 10px;
}

.inputContainer svg:not(:global(.no-stroke)) {
  stroke: currentColor;
}

.inputContainer.start input {
  padding-left: 34px;
}

.inputContainer.end input {
  padding-right: 45px;
}