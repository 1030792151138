.avatar,
.buttons {
  display: flex;
  gap: 8px;
}

.buttons {
  align-items: center;
}

.text {
  flex: 1;
  padding: 8px;
}