.dateInput {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 16px;
  width: 100%;
  box-sizing: border-box;
}

.dateInput button {
  border: 1px solid var(--gray-300);
  padding: 10px 14px 10px 14px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 var(--box-shadow-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--text-black);
  font-family: inherit;
  background: transparent;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dateInput label span {
  color: var(--error);
}


.dateInput button:hover:not(:disabled) {
  border-color: var(--primary);
}

.dateInput button:focus:not(:disabled),
.dateInput button:focus-visible:not(:disabled) {
  border-color: var(--primary);
  outline-color: var(--primary);
  box-shadow: 0 0 0 4px var(--primary-light);
}

.dateInput button:disabled{
  border-radius: 8px;
  border: 1px solid var(--gray-300);
  background: var(--gray-100);
  box-shadow: 0 1px 2px 0 var(--box-shadow-color);
}