.stepper {
  display: flex;
  gap: 16px;
  align-items: center;
}

.step {
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--gray-500);
}

.step span {
  border-radius: 50%;
  border: 1px solid var(--gray-border);
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active,
.step:hover  {
  color: var(--text-black);
}

.active span {
  border-color: var(--primary);
  background-color: var(--primary);
  color: var(--text-white);
}