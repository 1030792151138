.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.menu {
  display: flex;
  justify-content: center;
  padding: 24px;
}

.container {
  flex: 1;
  text-align: center;
}


.content {
  padding: 6rem;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 900px;
  width: 100%;
  box-sizing: border-box;
  background: conic-gradient(from 90deg at 1px 1px,#0000 90deg,var(--primary-light) 0);
  background-size: 5rem 5rem;
  box-shadow: inset 0 0 3rem 3rem white;
  border-radius: 14rem;
  text-align: left;
}
