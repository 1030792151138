.card {
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--gray-border);
  background: #FFF;
  box-shadow: 0 2px 4px 0 var(--box-shadow-color);
}

.card.noPadding {
  padding: 0;
}


.secondary {
  background-color: var(--gray-100);
}

.card.subtable {
  border-radius: 0;
  border: none;
  box-shadow: none;
  background-color: var(--gray-100);
}