.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.spacing-1 {
  gap: 8px
}

.spacing-2 {
  gap: 16px
}

.spacing-3 {
  gap: 24px
}

.grow {
  flex: 1;
  width: 100%;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-start {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-space-between {
  justify-content: space-between;
}