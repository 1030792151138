.link {
  color: var(--text-black);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration-line: underline;
}

.link svg {
  stroke: currentColor;
}

.link:hover {
  color: var(--primary);
}

.link.primary {
  color: var(--primary);
  text-decoration-line: none;
}

.link.primary:hover {
  text-decoration-line: underline;
}

.link svg {
  stroke: currentColor;
}