html, body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  /* font-family: IBM Plex Sans; */
}

#root {
  height: 100%;
}

a, a:hover, a:active, a:visited {
  text-decoration: none;
  color: inherit;
}

* {
  box-sizing: border-box;
}

div::-webkit-scrollbar {
  width: 10px;
}

div::-webkit-scrollbar-track {
  background: #E4E7EA;
  border-radius: 5px;
}

div::-webkit-scrollbar-thumb {
  background: #C7CED1;
  border-radius: 5px;
  border: 3px solid #E4E7EA;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.ms-DatePicker-goToday {
  right: 13px;
}

.ms-DatePicker [aria-live=assertive], .ms-DatePicker [role=alert] {
  display: none;
}

.ms-Dialog-header {
  background: #F2F5FA;
  margin-bottom: 35px;
}

.ms-Dialog-title {
  padding: 17px 24px 14px 24px;
}

:root {
  /* --primary: #4187B4;
  --primary-dark: #284767; */
  --main-dark: #023047;
  --gray-light: #FAF6F6;
  --gray: #C0CFD8;
  --gray-neutral: #859097;
  --gray-dark: #2D3A4F;
  --red: #D02317;
  --green: #14A660;
  --purple: #7C72AF;
  --turquoise: #72AFAF;
  --magenta: #AF729A;
  --broun: #AF8F72;

  --primary-light: #D8EBF5;
  --primary-contrast-light: #C5E1F0;
  --primary-contrast-dark: #9ECDE7;
  --primary: #3D9BCE;
  --primary-dark: #27729B;
  --text-white: #FFFFFF;
  --text-black: #0F2D3E;
  --text-primary: #3D9BCE;
  --gray-100: #F2F4F7;
  --gray-200: #EAECF0;
  --gray-300: #D0D5DD;
  --gray-400: #98A2B3;
  --gray-500: #667085;
  --gray-800: #1D2939;
  --gray-900: #101828;
  --gray-border: #E6E9EB;
  --gray-overlay: #0F2D3E;
  /* with 70% opacity */
  --gray-background: #F9FBFC;
  --success-light: #DCF4E9;
  --success: #12B76A;
  --success-dark: #0E8B51;
  --warning-light: #FFF3DE;
  --warning: #FDB022;
  --warning-dark: #CA8402;
  --error-light: #FDE3E1;
  --error: #F04438;
  --error-dark: #BE1A0E;


  --box-shadow-color: #1018280D;
  --app-backgound-color: #FCFDFD;
  --menu-backgound-color: #FFFFFF;
  --notification-color: #F75050;
}