.notification {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.notification>span {
  width: 24px;
  color: var(--primary);
}

.notification>div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.notification.read>span {
  color: var(--gray-500);
}
