.tag {
  display: inline-flex;
  padding: 2px 8px;
  align-items: center;
  gap: 4px;

  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  border-radius: 16px;
}

.default {
  color: var(--text-black);
  background-color: var(--gray-100);
}

.info {
  color: var(--primary);
  background-color: var(--primary-light);
}

.success {
  color: var(--success);
  background-color: var(--success-light);
}

.warning {
  color: var(--warning);
  background-color: var(--warning-light);
}

.error {
  color: var(--error);
  background-color: var(--error-light);
}

.tag svg {
  stroke: currentColor;
}