.notification {
  display: flex;
  padding: 12px 16px;
  gap: 10px;
  border-radius: 8px;
  background: var(--gray-800);
  border: 1px solid var(--gray-800);
  margin-top: 5px;
}

.error {
  border-color: var(--error);
  box-shadow: 0 6px 10px 0 var(--box-shadow-color);
}

.success {
  border-color: var(--success);
  box-shadow: 0 6px 10px 0 var(--box-shadow-color);
}

.warning {
  border-color: var(--warning);
  box-shadow: 0 6px 10px 0 var(--box-shadow-color);
}

p.text {
  color: var(--text-white);
  text-align: left;
}

p.subtext {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--text-white);
}