.wrapper {
  display: flex;
  height: 100%;
  font-family: IBM Plex Sans;
  color: var(--text-black);
  background-color: var(--app-backgound-color);
}

.wrapper main {
  flex: 1;
  display: flex;
  flex-direction: column;
}
