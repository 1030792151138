.menu {
  list-style: none;
  margin: 0;
  padding: 10px 0;
}

.menu li {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: default;
}

.menu li:hover:not(:disabled) {
  background: var(--gray-100);
}

.default svg {
  stroke: var(--gray-neutral);
}

.danger svg {
  stroke: var(--red);
}