.emptyCard {
  display: flex;
  flex-direction: column;
  gap: 24px;
}


.emptyCard button {
  margin: 0 auto;
}

/* .caseCard {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
}

.cardBody {
  display: flex;
}

.cardBodyDetails {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.caseDetails {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
} */

.fittingCard {
  padding: 0;
}

.fitting {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid var(--gray-border);
}
.finished {
  position: relative;
  z-index: 1;
}

.finished:after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;
  background: repeating-linear-gradient( -45deg, var(--primary-light), var(--primary-light) 18px, var(--text-white) 18px, var(--text-white) 36px);
  z-index: -1;
}



.delimiter {
  border-right: 1px solid var(--gray-border);
  padding-right: 16px;
}

.fittingBody {
  flex: 1;
}

