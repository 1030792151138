.card {
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid var(--gray-border);
  background: var(--text-white);
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}

.card::after {
  content: ' ';
  background: conic-gradient(from 90deg at 1px 1px,#0000 90deg,var(--primary-light) 0);
  background-size: 1.5rem 1.5rem;
  box-shadow: inset 0 0 1rem 1rem white;
  position: absolute;
  left: 50%;
  right: -20px;
  top: -20px;
  bottom: 0;
  transform: rotateZ(45deg);
  z-index: -1;
}
