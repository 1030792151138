.grid {
  flex: 1;
  position: relative;
  padding: 0;
}


.gridHeader,
.gridRow {
  display: flex;
  font-size: 12px;
  color: var(--text-black);
}

.gridRow {
  border-top: 1px solid var(--gray-border);
}

.gridHeader {
  font-weight: 700;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.gridCell {
  display: flex;
  align-items: center;
  padding: 8px 12px 8px 24px;
}

.gridHeader .gridCell:hover,
.gridRow:hover {
  background-color: var(--gray-100);
  cursor: default;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 16px 0;
  box-sizing: border-box;
}

.pagination button {
  width: 36px;
  height: 36px;
}

.pagination button.active {
  background-color: var(--text-white)!important;
}

.rotateAnim {
  transition: all ease-in .2s;
}

.rotate180 {
  transform: rotateZ(180deg);
}