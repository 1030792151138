.flex {
  display: flex;
  height: 100%;
}

.vertical {
  flex-direction: column;
}

.spaceBetween {
  justify-content: space-between;
}

.center {
  align-items: center;
}


.middle {
  justify-content: center;
}

.end {
  justify-content: flex-end;
}

.grow {
  flex: 1;
}