.dentalArches {
  position: relative;
  width: 300px;
  padding: 8px 0;
  box-sizing: border-box;
  /* background-image: url('../../../public/structura-dentara-final.svg'); */
  color: var(--gray-400);
  height: 438px;
}


.tooth {
  position: absolute;
}

.tooth button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

/* default color */
.tooth svg path:global(.fundal) {
  stroke: var(--gray-400);
  stroke-width: 1.5;
}

.tooth svg path:global(.interior) {
  fill: var(--gray-300);
}

.tooth svg path:global(.interior.stroke) {
  stroke: var(--gray-300);
}

.tooth svg path:global(.text) {
  fill: var(--gray-400);
}


/* primary selected color */
.editable .tooth:hover svg path:global(.fundal),
.tooth.selectedPrimary svg path:global(.fundal) {
  stroke: var(--primary);
  fill: var(--primary-contrast-light);
}

.editable .tooth:hover svg path:global(.interior),
.tooth.selectedPrimary svg path:global(.interior) {
  fill: var(--primary);
}

.editable .tooth:hover svg path:global(.interior.stroke),
.tooth.selectedPrimary svg path:global(.interior.stroke) {
  stroke: var(--primary);
}

.editable .tooth:hover svg path:global(.text),
.tooth.selectedPrimary svg path:global(.text) {
  fill: var(--primary);
}

/* secondary selected color */

.tooth.selectedSecondary svg path:global(.fundal) {
  stroke: var(--primary-contrast-dark);
}

.tooth.selectedSecondary svg path:global(.interior) {
  fill: var(--primary-contrast-dark);
}

.tooth.selectedSecondary svg path:global(.interior.stroke) {
  stroke: var(--primary-contrast-dark);
}

.tooth.selectedSecondary svg path:global(.text) {
  fill: var(--primary-contrast-dark);
}

.tooth11 {
  left: 120px;
  top: 0;
}

.tooth12 {
  left: 93px;
  top: 6px;
}

.tooth13 {
  left: 62px;
  top: 25px;
}

.tooth14 {
  left: 36px;
  top: 53px;
}

.tooth15 {
  left: 18px;
  top: 84px;
}

.tooth16 {
  left: 8px;
  top: 108px;
}

.tooth17 {
  left: 3px;
  top: 145px;
}

.tooth18 {
  left: 1px;
  top: 179px;
}

.tooth21 {
  right: 116px;
  top: 0;
}

.tooth22 {
  right: 91px;
  top: 7px;
}

.tooth23 {
  right: 60px;
  top: 25px;
}

.tooth24 {
  right: 34px;
  top: 53px;

}

.tooth25 {
  right: 16px;
  top: 84px;
}

.tooth26 {
  right: 6px;
  top: 108px;
}

.tooth27 {
  right: 1px;
  top: 145px;
}

.tooth28 {
  right: -1px;
  top: 179px;
}

.tooth31 {
  right: 127px;
  bottom: -4px;
}

.tooth32 {
  right: 102px;
  bottom: 1px;
}

.tooth33 {
  right: 69px;
  bottom: 13px;
}

.tooth34 {
  right: 45px;
  bottom: 37px;
}

.tooth35 {
  right: 27px;
  bottom: 63px;
}

.tooth36 {
  right: 15px;
  bottom: 85px;
}

.tooth37 {
  right: 5px;
  bottom: 124px;
}

.tooth38 {
  right: -1px;
  bottom: 159px;
}


.tooth41 {
  left: 131px;
  bottom: -4px;
}

.tooth42 {
  left: 108px;
  bottom: 0;
}

.tooth43 {
  left: 75px;
  bottom: 13px;
}

.tooth44 {
  left: 46px;
  bottom: 36px;
}

.tooth45 {
  left: 28px;
  bottom: 62px;
}

.tooth46 {
  left: 16px;
  bottom: 84px;
}

.tooth47 {
  left: 6px;
  bottom: 124px;
}

.tooth48 {
  left: 0;
  bottom: 158px;
}