.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}

.overlay:before {
  content: ' ';
  position: absolute;
  opacity: 0.7;
  background: var(--gray-overlay);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: var(--error);
}

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #fff;
  box-shadow: 30px 0 0 var(--primary-dark);
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: var(--primary-dark);
  box-shadow: 30px 0 0 #fff;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8)
  }

  50% {
    transform: rotate(360deg) scale(1.2)
  }

  100% {
    transform: rotate(720deg) scale(0.8)
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 var(--primary-dark);
  }

  50% {
    box-shadow: 0 0 0 var(--primary-dark);
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 var(--primary-dark);
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #fff;
  }

  50% {
    box-shadow: 0 0 0 #fff;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #fff;
    margin-top: 0;
  }
}