button.button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  gap: 5px;
  white-space: nowrap;
}

.large {
  padding: 16px 28px;
  line-height: 28px;
  font-size: 18px;
}

.medium {
  padding: 10px 18px;
  line-height: 24px;
  font-size: 16px;
}

.small {
  padding: 8px 14px;
  line-height: 20px;
  font-size: 14px;
}


.contained {
  border-radius: 8px;
  border: 1px solid var(--primary-dark);
  background: var(--primary);
  box-shadow: 0 0 0 1px #FFFFFF33 inset;
  color: var(--text-white);
}

.contained:hover:not(:disabled) {
  background: var(--primary-dark);
}

.contained:focus:not(:disabled),
.contained:focus-visible:not(:disabled) {
  box-shadow: 0 0 0 4px var(--primary-light);
  outline-color: white;
}

.contained:disabled {
  border-color: var(--primary-light);
  background: var(--primary-light);
}


.outlined {
  border-radius: 8px;
  border: 1px solid var(--gray-300);
  background: var(--text-white);
  box-shadow: 0 1px 2px 0 var(--box-shadow-color);
  color: var(--text-black);
}

.outlined:hover:not(:disabled) {
  background: var(--gray-100);
}

.outlined:focus:not(:disabled),
.outlined:focus-visible:not(:disabled) {
  box-shadow: 0 0 0 4px var(--primary-light);
  outline-color: white;
}

.outlined:disabled {
  color: var(--gray-300);
}


.text {
  border-radius: 8px;
  border: none;
  color: var(--primary);
  background: var(--text-white);
}

.text:hover:not(:disabled) {
  background: var(--primary-light);
}

.text:focus:not(:disabled),
.text:focus-visible:not(:disabled) {
  box-shadow: 0 0 0 4px var(--primary);
  outline-color: white;
}

.text:disabled {
  color: var(--gray-300);
  background: var(--text-white);
}

.danger {
  border-radius: 8px;
  border: 1px solid var(--error);
  background: var(--text-white);
  box-shadow: 0 1px 2px 0 var(--box-shadow-color);
  color: var(--error-dark);
}

.danger:hover:not(:disabled) {
  background: var(--error-light);
}

.danger:focus:not(:disabled),
.danger:focus-visible:not(:disabled) {
  box-shadow: 0 0 0 4px var(--error-light);
  outline-color: white;
}

.danger:disabled {
  color: var(--gray-300);
}

.button svg {
  stroke: currentColor;
}