.header {
  display: flex;
  justify-content: space-between;
  padding: 16px 32px;
  border-bottom: 1px solid var(--gray-border);
}

.page {
  display: flex;
  padding: 24px 32px 0 32px;
  margin-bottom: 24px;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  overflow: auto;
}