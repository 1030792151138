.current {
  border-radius: 50%;
  border: 1px solid var(--gray-border);
  width: 42px;
  height: 42px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  color: var(--gray-500);
  font-size: 16px;
}
