.calendar {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.month {
  display: flex;
  /* padding: 12px; */
  justify-content: space-between;
  align-items: center;
  /* height: 56px; */
  box-sizing: border-box;
}

.weeks {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.week {
  display: flex;
}

.day {
  display: flex;
  width: 34px;
  height: 34px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  color: var(--text-black);
  background: transparent;
  border: none;
  text-align: center;
  position: relative;
  z-index: 1;
  padding: 0;
}

.day:focus {
  outline: none;
}

.fade {
  color: var(--gray-400);
}

.today {
  position: relative;
}

.today::after {
  content: '';
  position: absolute;
  top: 27px;
  bottom: 3px;
  left: 15px;
  right: 15px;
  border-radius: 50%;
  z-index: 0;
  background: var(--notification-color);
}

.selected {
  color: var(--primary);
  background-color: var(--gray-100);
}

.start {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  color: var(--text-white);
  background-color: var(--primary);
}

.end {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: var(--text-white);
  background-color: var(--primary);
}

.control, .control:hover, .control i {
  color: #201f1e;
  font-size: 12px;
}

.button svg {
  stroke: var(--gray-400);
}

.button:hover svg {
  stroke: var(--gray-800);
}