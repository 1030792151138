.responsive-xs-1,
.responsive-xs-2,
.responsive-xs-3,
.responsive-xs-3-grow,
.responsive-sm-1,
.responsive-sm-2,
.responsive-sm-3,
.responsive-sm-3-grow,
.responsive-md-1,
.responsive-md-2,
.responsive-md-3,
.responsive-md-3-grow,
.responsive-lg-1,
.responsive-lg-2,
.responsive-lg-3,
.responsive-lg-3-grow,
.responsive-xlg-1,
.responsive-xlg-2,
.responsive-xlg-3,
.responsive-xlg-3-grow {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem
}



/* 320px — 480px: Mobile devices. xs */
@media (min-width: 320px) {
  .responsive-xs-2 {
    grid-template-columns: 1fr 1fr;
  }

  .responsive-xs-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .responsive-xs-3-grow {
    grid-template-columns: 2fr 1fr;
  }
}

/* 481px — 768px: iPads, Tablets. sm */
@media (min-width: 481px) {

  .responsive-sm-1,
  .responsive-sm-1[class*=responsive-xs] {
    grid-template-columns: 1fr;
  }

  .responsive-sm-2,
  .responsive-sm-2[class*=responsive-xs] {
    grid-template-columns: 1fr 1fr;
  }

  .responsive-sm-3,
  .responsive-sm-3[class*=responsive-xs] {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .responsive-sm-3-grow,
  .responsive-sm-3-grow[class*=responsive-xs] {
    grid-template-columns: 2fr 1fr;
  }
}

/* 769px — 1024px: Small screens, laptops. md */
@media (min-width: 769px) {

  .responsive-md-1,
  .responsive-xlg-1[class*=responsive-xs],
  .responsive-xlg-1[class*=responsive-sm] {
    grid-template-columns: 1fr;
  }

  .responsive-md-2,
  .responsive-md-2[class*=responsive-xs],
  .responsive-md-2[class*=responsive-sm] {
    grid-template-columns: 1fr 1fr;
  }

  .responsive-md-3,
  .responsive-md-3[class*=responsive-xs],
  .responsive-md-3[class*=responsive-sm] {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .responsive-md-3-grow,
  .responsive-md-3-grow[class*=responsive-xs],
  .responsive-md-3-grow[class*=responsive-sm] {
    grid-template-columns: 2fr 1fr;
  }
}

/* 1025px — 1200px: Desktops, large screens. lg */
@media (min-width: 1025px) {

  .responsive-lg-1,
  .responsive-lg-1[class*=responsive-xs],
  .responsive-lg-1[class*=responsive-sm],
  .responsive-lg-1[class*=responsive-md] {
    grid-template-columns: 1fr;
  }

  .responsive-lg-2,
  .responsive-lg-2[class*=responsive-xs],
  .responsive-lg-2[class*=responsive-sm],
  .responsive-lg-2[class*=responsive-md] {
    grid-template-columns: 1fr 1fr;
  }

  .responsive-lg-3,
  .responsive-lg-3[class*=responsive-xs],
  .responsive-lg-3[class*=responsive-sm],
  .responsive-lg-3[class*=responsive-md] {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .responsive-lg-3-grow,
  .responsive-lg-3-grow[class*=responsive-xs],
  .responsive-lg-3-grow[class*=responsive-sm],
  .responsive-lg-3-grow[class*=responsive-md] {
    grid-template-columns: 2fr 1fr;
  }
}

/* 1201px and more — Extra large screens, TV. xlg */
@media (min-width: 1201px) {

  .responsive-xlg-1,
  .responsive-xlg-1[class*=responsive-xs],
  .responsive-xlg-1[class*=responsive-sm],
  .responsive-xlg-1[class*=responsive-md],
  .responsive-xlg-1[class*=responsive-lg] {
    grid-template-columns: 1fr;
  }

  .responsive-xlg-2,
  .responsive-xlg-2[class*=responsive-xs],
  .responsive-xlg-2[class*=responsive-sm],
  .responsive-xlg-2[class*=responsive-md],
  .responsive-xlg-2[class*=responsive-lg] {
    grid-template-columns: 1fr 1fr;
  }

  .responsive-xlg-3,
  .responsive-xlg-3[class*=responsive-xs],
  .responsive-xlg-3[class*=responsive-sm],
  .responsive-xlg-3[class*=responsive-md],
  .responsive-xlg-3[class*=responsive-lg] {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .responsive-xlg-3-grow,
  .responsive-xlg-3-grow[class*=responsive-xs],
  .responsive-xlg-3-grow[class*=responsive-sm],
  .responsive-xlg-3-grow[class*=responsive-md],
  .responsive-xlg-3-grow[class*=responsive-lg] {
    grid-template-columns: 2fr 1fr;
  }
}