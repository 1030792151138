.caseCard {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
}

.cardBody {
  display: flex;
}

.cardBodyDetails {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.caseDetails {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
}