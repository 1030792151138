button.icon {
  background: transparent;
  border: none;
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
}

.outlined {
  border-radius: 8px;
  border: 1px solid var(--gray-300);
  background: var(--text-white);
  box-shadow: 0 1px 2px 0 var(--box-shadow-color);
  color: var(--text-black);
}

.outlined:hover:not(:disabled) {
  background: var(--gray-100);
}

.outlined:focus:not(:disabled),
.outlined:focus-visible:not(:disabled) {
  box-shadow: 0 0 0 4px var(--primary-light);
  outline-color: white;
}

.outlined:disabled {
  color: var(--gray-300);
}

button.icon svg {
  stroke: currentColor;
}

.danger {
  color: var(--error);
}