.rangePicker {
  display: flex;
  gap: 16px;
}

.buttons {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  border-top:1px solid var(--gray-border);
  padding: 12px 4px;
}

.range {
  display: flex;
  flex-direction: column;
  gap: 4px;
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1;
  border-left:1px solid var(--gray-border);
}

.range li button {
  border: none;
  background: transparent;
  display: block;
  padding: 10px 2px 10px 14px;
  width: 100%;
  text-align: left;
  box-sizing: border-box;
}

.range li button:hover,
.range li button.selected {
  background-color: var(--gray-100);
}

.rangePickerButton {
  min-width: 265px;
  justify-content: space-between !important;
}