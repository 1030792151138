.menu {
  width: 250px;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  box-sizing: border-box;
  border-right: 1px solid var(--gray-border);
  background-color: var(--menu-backgound-color);
}

.companyName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
}

.menuList {
  flex: 1;
  list-style: none;
  margin: 24px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--text-black);
}

.buttonLink,
.menuLink {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border-radius: 8px;
}

.buttonLink {
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-start;
  color: var(--text-black);
  border: none;
  background: transparent;
}

.menuLink:global(.active) {
  color: var(--primary);
  background-color: var(--primary-light);
}

.buttonLink:hover,
.menuLink:hover {
  background-color: var(--gray-100);
}

.buttonLink svg,
.menuLink svg {
  stroke: currentColor;
}


.addCase {
  margin: 8px 0;
  padding: 16px 0;
  border-top: 1px solid var(--gray-border);
  border-bottom: 1px solid var(--gray-border);
}


.menuLinkButton {
  font-weight: 600;
  padding: 8px;
  line-height: 24px;
  font-size: 16px;

  border-radius: 8px;
  border: 1px solid var(--primary-dark);
  background: var(--primary);
  box-shadow: 0 0 0 1px #FFFFFF33 inset;
  color: var(--text-white) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuLinkButton:hover:not(:disabled) {
  background: var(--primary-dark);
}

.menuLinkButton:focus:not(:disabled),
.menuLinkButton:focus-visible:not(:disabled) {
  box-shadow: 0 0 0 4px var(--primary-light);
  outline-color: transparent;
}

.menuLinkButton:disabled {
  border-color: var(--primary-light);
  background: var(--primary-light);
}


.menuBottom {
  margin-bottom: 25px;
}

.menuBottom button {
  transform: rotateY(180deg);
}

.menu.collapsed {
  width: 70px;
}

.menu.collapsed .buttonLink span,
.menu.collapsed .menuLinkButton span,
.menu.collapsed .menuLink span {
  display: none;
}

.menuLinkButton.disabled,
.menuLink.disabled {
  color: var(--gray-400) !important;
  pointer-events: none;
  cursor: default;
}


.menuLinkButton.disabled {
  background-color: var(--gray-200);
}

.menuLinkButton.disabled svg path {
  stroke: currentColor!important;
}