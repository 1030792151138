.userLogo {
  color: var(--text-black);
  text-transform: uppercase;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}


.default {
  height: 40px;
  width: 40px;
  background-color: var(--primary-light);
}

.big {
  height: 80px;
  width: 80px;
  font-size: 36px;
  box-shadow: 0 0 1px 1px var(--box-shadow-color);
}

.userLogo img {
  display: block;
  max-width: 100%;
}