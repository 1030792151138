.feature {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  display: flex;
  gap: 8px;
}

.listTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title svg {
  stroke: currentColor;
}
