.metricCard {
  color: var(--gray-400);
  display: flex;
}

.metricCard svg {
  stroke: currentColor;
}
.metric {
  flex: 1;
}
