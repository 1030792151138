.feature {
  display: flex;
  align-items: center;
}

.description {
  flex: 1;
  display: flex;
  flex-direction: column;
}
