.container {
  position: relative;
  height: 340px;
  width: 260px;
}

.shrink {
  position: absolute;
  top: -60px;
  scale: .7;
}