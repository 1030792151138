.fittingInput {
  position: relative;
  display: flex;
  flex-direction: column;
}

.fittingInput input {
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 var(--box-shadow-color);
  padding: 12px 16px 24px 16px;
  max-width: 54px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: var(--text-black);
  font-family: inherit;
  resize: none;
}

.fittingInput input:hover:not(:disabled) {
  border-color: var(--primary);
}

.fittingInput input:focus:not(:disabled),
.fittingInput input:focus-visible:not(:disabled) {
  border-color: var(--primary);
  outline-color: var(--primary);
  box-shadow: 0 0 0 4px var(--primary-light);
}

.fittingInput input:disabled {
  border-radius: 8px;
  border: 1px solid var(--gray-300);
  background: var(--gray-100);
  box-shadow: 0 1px 2px 0 var(--box-shadow-color);
}

.fittingInput span {
  position: absolute;
  font-size: 12px;
  color: var(--gray-400);
  bottom: 12px;
  left: 0;
  right: 0;
  text-align: center;
}

.calendar {
  padding: 16px;
}

.fittingForm {
  padding: 16px;
  border-left:1px solid var(--gray-border);
}

.buttons {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  border-top:1px solid var(--gray-border);
  padding: 12px 16px;
}
