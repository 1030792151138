.reportHeader {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid var(--gray-border);
}

.reportBody {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tenderCard {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  background-color: var(--gray-100);
}

.tenderCard > div {
  display: flex;
  gap: 8px;
}