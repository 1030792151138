.typography {
  color: var(--text-black);
  margin: 0;
}

.display1.typography {
  font-size: 56px;
  font-weight: 700;
  line-height: 72px;
}

.display2.typography {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
}

h1.typography {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
}

h2.typography {
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
}

h3.typography {
  font-size: 28px;
  font-weight: 600;
  line-height: 38px;
}

h4.typography {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

h5.typography {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

h6.typography {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px
}

p.typography {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

span.typography {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px
}

label.typography {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.bold.typography {
  font-weight: 600;
}

.uppercase.typography {
  text-transform: uppercase;
}

.center.typography  {
  text-align: center;
}

.secondary.typography {
  color: var(--gray-500);
}

.danger.typography {
  color: var(--error);
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}